import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { useLockScrollEffect } from './useLockScrollEffect';

import qrcode from '../image/yuntucao-qrcode.png';

export const MPrevDemo = (props) => {

    useLockScrollEffect();

    return createPortal(
        <Fragment>
            <div className="mask"></div>
            <div className="m-prev-dialog prev-dialog">
                <div className="title">体验手机版Demo<i className="dialog-close" onClick={props.onClose}></i></div>
                <div className="main">
                    <img className="qrcode" src={qrcode} />
                    <div className="scan-description">请使用微信扫描二维码，在手机上体验 demo。</div>
                </div>
            </div>
        </Fragment>
        , document.getElementById('root'));
};

MPrevDemo.propTypes = {
    onClose: PropTypes.func,
};
