import { useEffect } from 'react';

export function useLockScrollEffect() {

    return useEffect(() => {

        document.body.style.overflowY = 'hidden';

        return () => {
            document.body.style.overflowY = '';
        };

    });

}
