import React, { Fragment } from 'react';

import { PvCollector } from 'components/report';
import { get_api_account as getApiAccount } from 'components/api';

import { Header } from 'components/header/index_header';
import { Banner } from './common/banner';
import { Footer } from './common/footer';
import { Demo } from './common/demo';

import './style.css';
// TODO临时解决TD样式污染
import 'components/css/pc/td-reset.less';

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    new PvCollector({ type: 1 }).report();

    getApiAccount().then((resp) => {
      if (!resp.data) {
        return;
      }

      this.setState({ user: resp.data });
    });
  }

  loginToCreateProduct() {
    const { state } = this;

    const path = '/dashboard';

    if (!state.user.id) {
      window.location.href = `https://${location.host}/login.html?redirect_url=${encodeURI(path)}`;
      return;
    }

    window.location.href = `https://${location.host}${path}`;
  }

  render() {
    const { state } = this;

    return (

      <Fragment>

        <Header
          onClickCreate={() => this.loginToCreateProduct()}
          loginUrl="/login.html"
          user={state.user}
        />

        <Banner onClickCreate={() => this.loginToCreateProduct()} />

        <div className="container role-intro-section">
          <h3 className="caption">几行代码 拥有和腾讯网一样的互动社区</h3>
          <p className="desc">让反馈变得便捷，轻巧</p>

          <div className="role-entry">
            <div className="wrap">
              <div className="role-block role-op">
                <span className="icon"></span>
                <div>
                  <div className="role">让运营人员</div>
                </div>
                <h3 className="caption">不再疲于运营</h3>
                <p className="desc">无需登录后台即可简单管理<br />
                  你与客户的沟通平台永远简洁有序</p>
              </div>
              <div className="role-block role-engineer">
                <span className="icon"></span>
                <div>
                  <div className="role">如果你是程序员</div>
                </div>
                <h3 className="caption">何必自建反馈</h3>
                <p className="desc">几行代码将兔小巢放入在任何地方，包括公众号、APP、H5、网站等</p>

              </div>
              <div className="role-block role-pm">
                <span className="icon"></span>
                <div>
                  <div className="role">帮产品经理</div>
                </div>
                <h3 className="caption">微信推送回复</h3>
                <p className="desc">
                  用户通过微信收到你的回复<br />
                  你与用户沟通更加高效
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container bright-spot-section">
          <div className="content">
            <h3 className="caption">解决你的后顾之忧</h3>
            <p className="desc">轻松定制属于自己的反馈平台，一站式满足管理、技术、客服、运营等多角色使用需求，同时享用安全稳定、高性能、高延展的底层设施。看似复杂的系统，从此触手可及。</p>
            <div className="bright-spots">
              <div className="spot spot-one-step">
                <strong>数据一键导出</strong>
                <p className="text">方便导出所有数据，方便数据的其他使用</p>
              </div>
              <div className="spot spot-no-inbreak">
                <strong>不介入数据</strong>
                <p className="text">只负责技术支撑，不介入任何产品数据</p>
              </div>
              <div className="spot spot-stable">
                <strong>稳定可靠</strong>
                <p className="text">腾讯门神等多个安全系统为反馈数据保驾护航</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container example-section">
          <h3 className="caption">超过<em className="number">10000</em>款产品正在使用</h3>
          <p className="desc">在那些你每天都在使用的产品里，都有我们的身影</p>
          <div className="examples">
            <div className="example-container">
              <div className="temp">
                <div className="example-header">APP</div>
                <div className="example-body">
                  <Demo name="柠檬" notClickable />
                  <Demo name="腾讯体育" notClickable />
                </div>
              </div>
              <div className="temp temp_mid">
                <div className="example-header">WEB</div>
                <div className="example-body">
                  <Demo name="微信公众号平台" notClickable />
                  <Demo name="腾讯问卷" notClickable />
                </div>
              </div>
              <div className="temp">
                <div className="example-header">小程序</div>
                <div className="example-body">
                  <Demo name="腾讯智慧零售" />
                  <Demo name="腾讯文档" />
                </div>
              </div>
            </div>
          </div>
          <p className="mission">让用户与你的沟通，简单、方便和温暖</p>
          <button className="btn btn-default" style={{ border: 0 }} onClick={() => this.loginToCreateProduct()}>立即免费使用</button>
        </div>

        <Footer pageType="HOME" />

      </Fragment>

    );
  }
}
