import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import { useLockScrollEffect } from './useLockScrollEffect';


export const MAppDemo = (props) => {

    useLockScrollEffect();

    return createPortal(
        <Fragment>
            <div className="mask"></div>

            <div className="m-app-dialog prev-dialog">
                <div className="title">
                    体验{props.type === 'miniapp' ? '小程序' : '手机APP'}
                    <i className="dialog-close" onClick={props.onClose}></i>
                </div>
                <div className="main">

                    {props.type === 'miniapp'
                        ? <img className="m-app-tucao-qrcode" src={props.qrcode} />
                        : <QRCode className="m-app-tucao-qrcode" size={200} level="H" value={props.url} />
                    }

                    <div className="scan-description">请使用微信扫描二维码，在手机上体验。</div>
                </div>
            </div>
        </Fragment>
        , document.getElementById('root'));
};

MAppDemo.propTypes = {
    type: PropTypes.oneOf(['app', 'web', 'miniapp']),
    qrcode: PropTypes.string,
    url: PropTypes.string,
    onClose: PropTypes.func
};
