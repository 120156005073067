import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MPrevDemo } from './m-prev-demo';

export function Banner(props) {
  const [isShow, toggleDemo] = useState(false);
  return (
      <div className="banner">
          <h2 className="title"><strong>让用户帮你</strong> 把产品做得更好</h2>
          <p className="sub-title">超过&nbsp;<em>10000</em>&nbsp;款产品正在使用的反馈互动社区</p>
          <div className="action">
              <div className="btn btn-default">
                  <span className="btn-text">体验Demo</span>
                  <span className="down-arrow"></span>
                  <div className="separator"></div>
                  <div className="dropdown">
                      <div className="dropdown-item">
                          <a href="//support.qq.com/products/1368" target="_blank" rel="noreferrer">电脑用户</a>
                      </div>
                      <div className="dropdown-item">
                          <a href="##" className="mobile-demo-prev" onClick={event => toggleDemo(true)}>手机用户</a>
                      </div>
                      <div className="dropdown-item">
                          <a href="//txc.qq.com/demo/tucao/admin" target="_blank" rel="noreferrer">管理后台</a>
                      </div>
                  </div>
              </div>
              <a href="##" onClick={props.onClickCreate} className="btn btn-primary">立即免费使用</a>
          </div>

          {isShow && <MPrevDemo onClose={event => toggleDemo(false)} />}
      </div>
  );
}

Banner.defaultProps = {
  link_logo: 'javascript:;',
};

Banner.propTypes = {
  onClickCreate: PropTypes.func.isRequired,
};
