import React, { Fragment, useState } from 'react';
import classNames from 'classnames';

import { MAppDemo } from './m-app-demo';
import PropTypes from 'prop-types';

import logo1 from 'components/assets-image/home-example/1-logo.jpg';
import logo2 from 'components/assets-image/home-example/9-logo.jpg';
import logo3 from 'components/assets-image/home-example/10-logo.jpg';
import logo4 from 'components/assets-image/home-example/4-logo.jpg';
import qrc5 from 'components/assets-image/home-example/5-qrcode.png';
import logo5 from 'components/assets-image/home-example/5-logo.jpg';
import qrc6 from 'components/assets-image/home-example/6-qrcode.png';
import logo6 from 'components/assets-image/home-example/6-logo.jpg';
import qrc7 from 'components/assets-image/home-example/8-qrcode.png';
import logo7 from 'components/assets-image/home-example/11-logo.png';
import logo8 from 'components/assets-image/home-example/8-logo.jpg';

const EXAMPLE = {
    '企业微信': {
        type: 'app',
        logo: logo1,
        url: 'https://support.qq.com/products/4478'
    },
    '腾讯体育': {
        type: 'app',
        logo: logo2,
        url: 'https://support.qq.com/products/1306'
    },
    '微信公众号平台': {
        type: 'web',
        logo: logo3,
        target: '_blank',
        url: 'https://support.qq.com/products/66522'
    },
    '腾讯问卷': {
        type: 'web',
        logo: logo4,
        target: '_blank',
        url: 'https://support.qq.com/products/1221'
    },
    '小经费': {
        type: 'miniapp',
        logo: logo5,
        qrcode: qrc5,
        url: 'javascript:;'
    },
    '腾讯文档': {
        type: 'miniapp',
        logo: logo6,
        qrcode: qrc6,
        url: 'javascript:;'
    },
    '腾讯智慧零售': {
        type: 'miniapp',
        logo: logo7,
        qrcode: qrc7,
        url: 'javascript:;'
    },
    '柠檬': {
        type: 'app',
        logo: logo8,
        url: 'https://support.qq.com/products/36664'
    }
};

export const Demo = (props) => {

    const [demoName, setDemo] = useState('');

    const item = EXAMPLE[props.name];

    if (!item) {
        console.log(`找不到 ${props.name} 的 EXAMPLE 配置`);
        return null;
    }

    const currentDemo = EXAMPLE[demoName];

    const onClick = (event) => {

        if (item.type !== 'web') {
            event.preventDefault();
            setDemo(props.name);
        }

    };

    return (

        <Fragment>
            {props.notClickable ? (
                <div className={classNames('example app-prv', props.className)}>
                    <img className="logo" src={item.logo} alt={props.name} />
                </div>
            ) : (
                <a
                    target={item.target}
                    href={item.url}
                    className={classNames('example app-prv', props.className)}
                    onClick={onClick}
                >
                    <img className="logo" src={item.logo} alt={props.name} />
                </a>
            )}

            {currentDemo && <MAppDemo onClose={event => setDemo('')} {...currentDemo} />}
        </Fragment>

    );
};

Demo.propTypes = {
    name: PropTypes.oneOf(Object.keys(EXAMPLE)).isRequired,
    url: PropTypes.string,
    onClose: PropTypes.func
};
